/* Estilos comuns */
.store-card {
  display: flex;
  align-items: center;
  padding: 40px;
  margin: 20px;
  border-radius: 15px;
  transition: background-color 1.0s, box-shadow 1.0s;
}

.store-info {
  display: flex;
  padding: 15px;
  flex-direction: column;
}

/* Variant A (Imagens quadradas, hover padrão) */
.variant-A .store-image {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  border-radius: 10px; /* Imagem quadrada */
}

.variant-A:hover {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1); /* Hover padrão */
}

/* Variant B (Imagens redondas, hover com fundo laranja) */
.variant-B .store-image {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  border-radius: 50%; /* Imagem redonda */
}

.variant-B:hover {
  background-color: #EA1D2C; /* Muda a cor de fundo no hover */
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2); /* Aumenta sombra no hover */
}
